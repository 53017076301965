import getConfig from "next/config";
import { useRouter } from "next/router";
import { bool, func, number } from "prop-types";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import CashFinancingContent from "./CashFinancingContent";

import api from "@api";

import SimpleButton from "@components/shared/SimpleButton";
import Typography from "@components/shared/Typography";
import { fontWeight, font } from "@components/shared/utils";

import { useTranslation, Trans } from "@lib/i18n";

import { useDeliveryMethods } from "@hooks/useDeliveryMethods";
import { useGetFormattedPrice } from "@hooks/useGetFormattedPrice";

import {
  calculatePricesAction,
  postDealAction,
  removePromoCodeAction,
} from "@redux/actions/dealActions";
import {
  useCashDepositAmountSelector,
  useB2BCashDepositAmountSelector,
  useCashOutstandingBalanceSelector,
  useB2BCashOutstandingBalanceSelector,
  useEcoBonusSelector,
  useConfigurationSelector,
} from "@redux/reducers/appConfig";
import { useIsUserTrustedSelector } from "@redux/reducers/auth";
import { useBusinessModelSelector } from "@redux/reducers/deal";

import {
  COUNTRIES,
  CTA_SOL_TYPES,
  DEAL_TYPE,
  DELIVERY_TYPE,
  SESSION_STORAGE_KEYS,
} from "@shared/constants";
import { dealStatusAllowsPromoCodeChanges } from "@shared/helper/dealStatusAllowsPromoCodeChanges";
import { getIsCardImprintEnabled } from "@shared/helper/getIsCardImprintEnabled";
import { isIndexOrConfigPage } from "@shared/helper/isIndexOrConfigPage";
import getPriceAlgorithm from "@shared/PriceAlgorithm/getPriceAlgorithm";

import { financingCashData, dealType } from "@types";

import { sessionStorageClient } from "utils/sessionStorageClient";

const { publicRuntimeConfig } = getConfig();

const { COUNTRY } = publicRuntimeConfig;

const VehicleQuantity = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${fontWeight("bold")};
`;

const CashFinancing = ({
  cashData,
  isModifiable,
  onModifyButtonClick,
  withDeliveryInfo,
  isB2BSelected,
  vehicleQuantity,
  isQuantityVisible,
  deal,
  bankTransferPaymentCompleted,
  isReadOnly,
}) => {
  const { t } = useTranslation();
  const priceAlgorithm = getPriceAlgorithm(COUNTRY);
  const dispatch = useDispatch();

  const ecoBonus = useEcoBonusSelector();
  const businessModel = useBusinessModelSelector();

  const deliveryMethods = useDeliveryMethods(businessModel);
  const getFormattedPrice = useGetFormattedPrice();
  const depositAmount = useCashDepositAmountSelector();
  const b2bDepositAmount = useB2BCashDepositAmountSelector();
  const showOutstandingBalance = useCashOutstandingBalanceSelector();
  const showB2BOutstandingBalance = useB2BCashOutstandingBalanceSelector();
  const configuration = useConfigurationSelector();
  const isCardImprintEnabled = getIsCardImprintEnabled(
    configuration,
    isB2BSelected
  );
  const isUserTrusted = useIsUserTrustedSelector();
  const router = useRouter();

  const {
    carConfiguration,
    financeSimulation: { promoCode },
    status,
    userProfile,
  } = deal;

  const deliveryPrices = deliveryMethods.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.id]: getFormattedPrice(Number(cur.netPrice)),
    };
  }, {});

  const target = isB2BSelected ? "b2b" : "b2c";

  const handlePromoCodeRemove = useCallback(async () => {
    if (!isUserTrusted) {
      api.removePromoCodeFromSession();
    }

    dispatch(removePromoCodeAction());
    dispatch(calculatePricesAction());

    sessionStorageClient.removeItem(SESSION_STORAGE_KEYS.PROMOCODE);

    if (deal?.currentDeal?.token) {
      dispatch(
        postDealAction(
          CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER,
          DEAL_TYPE.DEAL,
          null,
          true
        )
      );
    }
  }, []);

  const structure = useMemo(() => {
    let specificItems = [];

    if (COUNTRY === COUNTRIES.IT) {
      specificItems = [
        {
          type: "item",
          isVisible: true,
          primaryText: `${t("basket.cataloguePrice")} ${
            carConfiguration.preconfiguration.vehicle.label
          }`,
          price: carConfiguration.preconfiguration.vehicle.netPrice,
          dataId: "cashfinancing-catalogueVehiclePrice",
        },
        {
          type: "item",
          isVisible: carConfiguration.options[0]?.netPrice > 0,
          primaryText: `${t("basket.cataloguePrice")} ${
            cashData.preconfigurationLabel
          }`,
          price: carConfiguration.options[0]?.netPrice,
          dataId: "cashfinancing-cataloguePackPrice",
        },
        {
          type: "promocode",
          isVisible: !isIndexOrConfigPage(router.pathname) && promoCode?.valid,
          primaryText: promoCode?.label,
          isRemovable: !isReadOnly && dealStatusAllowsPromoCodeChanges(status),
          onRemove: handlePromoCodeRemove,
          removeTitle: t("promoCodes.remove"),
          price: (promoCode?.discountAmount || 0) * -1,
          dataId: "cashfinancing-promocode",
        },
        {
          type: "item",
          isVisible: true,
          primaryText: t("basket.governmentDiscount"),
          price: cashData.govDiscountPrice * -1,
          dataId: "cashfinancing-discountprice",
        },
        {
          type: "divider",
          isVisible: true,
          spacing: "1rem",
        },
        {
          type: "item",
          isVisible: true,
          primaryText: t("basket.promoPrice"),
          price: cashData.productPromoPrice,
          dataId: "cashfinancing-baseprice",
        },
      ];
    } else {
      specificItems = [
        {
          type: "item",
          isVisible: true,
          primaryText: carConfiguration.preconfiguration.vehicle.label,
          price: cashData.basePrice,
          dataId: "cashfinancing-baseprice",
        },
        {
          type: "item",
          isVisible: cashData.options.length && cashData.packPrice.netPrice > 0,
          primaryText: `${t("customize.pack")} ${
            cashData.preconfigurationLabel
          }`,
          price: cashData.packPrice.netPrice,
          dataId: "cashfinancing-packprice",
        },
      ];
    }

    return [
      ...specificItems,
      {
        type: "items",
        isVisible: cashData.accessories.length,
        primaryText: t("customize.accessory"),
        items: cashData.accessories.map(({ id, label, netPrice }) => ({
          secondaryText: label,
          price: Number(netPrice),
          dataId: `cashfinancing-accessoryprice-${id}`,
        })),
      },
      {
        type: "items",
        isVisible: cashData.services.length,
        items: cashData.services.map(({ id, label, netPrice }) => ({
          primaryText: label,
          price: Number(netPrice),
          dataId: `cashfinancing-serviceprice-${id}`,
        })),
      },
      {
        type: "item",
        isVisible:
          cashData.isDeliveryIncluded &&
          Number.isFinite(cashData.deliveryPrice),
        primaryText:
          userProfile.deliveryData.type === DELIVERY_TYPE.HOME
            ? t("customize.homeDelivery")
            : t("customize.delivery"),
        price: Number(cashData.deliveryPrice),
        dataId: "cashfinancing-deliveryprice",
      },
      {
        type: "item",
        isVisible: Boolean(cashData.registrationDiscountAmount),
        primaryText: t("customize.selfRegistration"),
        price: Number(cashData.registrationDiscountAmount * -1),
        dataId: "cashfinancing-registrationprice",
      },
      {
        type: "promocode",
        isVisible:
          !isIndexOrConfigPage(router.pathname) &&
          promoCode?.valid &&
          COUNTRY !== COUNTRIES.IT,
        primaryText: promoCode?.label,
        isRemovable: !isReadOnly && dealStatusAllowsPromoCodeChanges(status),
        onRemove: handlePromoCodeRemove,
        removeTitle: t("promoCodes.remove"),
        price: (promoCode?.discountAmount || 0) * -1,
        dataId: "cashfinancing-promocode",
      },
      {
        type: "divider",
        isVisible: true,
        spacing: isQuantityVisible ? "0.5rem" : "2rem",
      },
      {
        type: "item",
        isVisible: true,
        primaryText: isQuantityVisible
          ? t("basket.singleVehicleTotal")
          : t("basket.totalIncludingVat"),
        price: cashData.totalPrice,
        dataId: "cashfinancing-totalprice",
      },
      {
        type: "formattedPrice",
        isVisible: isB2BSelected && !isQuantityVisible,
        primaryText: `${t("basket.total")} ${t("basket.withoutVat")}`,
        price: getFormattedPrice(cashData.totalPriceWithoutTax),
      },
      {
        type: "group",
        isVisible: isQuantityVisible,
        items: [
          {
            type: "divider",
            isVisible: true,
            spacing: "0.5rem",
          },
          {
            type: "customItem",
            isVisible: true,
            primaryText: t("basket.vehicleQuantityLabel"),
            content: (
              <VehicleQuantity>
                {t("basket.vehicleQuantityValue", {
                  quantity: vehicleQuantity,
                })}
              </VehicleQuantity>
            ),
          },
          {
            type: "divider",
            isVisible: true,
            spacing: "0.5rem",
          },
          {
            type: "item",
            isVisible: true,
            primaryText: t("basket.totalIncludingVat"),
            price: Number(
              priceAlgorithm.times(cashData.totalPrice, vehicleQuantity)
            ),
            dataId: "cashfinancing-multi-totalprice",
          },
          {
            type: "formattedPrice",
            isVisible: isB2BSelected,
            primaryText: t("basket.totalWithoutVat"),
            price: getFormattedPrice(
              priceAlgorithm.times(
                cashData.totalPriceWithoutTax,
                vehicleQuantity
              )
            ),
          },
        ],
      },
      {
        type: "typography",
        isVisible: ecoBonus.enabled,
        margin: "1rem 0 0",
        content: isQuantityVisible
          ? t(`price.${target}.multipleVehiclesEcologicalBonus`, {
              ecoBonusPerVehicle: getFormattedPrice(Number(ecoBonus.netAmount)),
              quantity: vehicleQuantity,
              total: getFormattedPrice(
                Number(
                  priceAlgorithm.times(ecoBonus.netAmount, vehicleQuantity)
                )
              ),
            })
          : t(`price.${target}.ecologicalBonus`, {
              amount: getFormattedPrice(Number(ecoBonus.netAmount)),
            }),
      },
      {
        type: "typography",
        isVisible: isCardImprintEnabled,
        content: t(`price.${target}.cardImprint`),
      },
      {
        type: "typography",
        isVisible: withDeliveryInfo,
        content: (
          <Trans
            i18nKey="price.deliveryInfo.cash"
            values={{ deliveryPrices }}
          />
        ),
      },
      {
        type: "content",
        isVisible: isModifiable,
        content: (
          <SimpleButton
            data-id="basket-financing-modify-button"
            onClick={onModifyButtonClick}
            withArrow
          >
            {t("general.modify")}
          </SimpleButton>
        ),
      },
      // deposit payment
      {
        type: "group",
        isVisible:
          (!isB2BSelected && showOutstandingBalance) ||
          (isB2BSelected && showB2BOutstandingBalance),
        items: [
          {
            type: "item",
            isVisible: true,
            primaryText: bankTransferPaymentCompleted
              ? t("myOrder.downPaymentCompleted")
              : t("basket.totalDepositPayable"),
            price: isB2BSelected ? b2bDepositAmount : depositAmount,
            dataId: "cashfinancing-totalDeposit",
          },
          // Outstanding balance payable on shipping
          {
            type: "item",
            isVisible: !bankTransferPaymentCompleted,
            primaryText: t("basket.outstandingBalance"),
            price:
              cashData.totalPrice -
              (isB2BSelected ? b2bDepositAmount : depositAmount),
            dataId: "cashfinancing-outstandingBalance",
          },
          // bank transfer payment
          {
            type: "item",
            isVisible: bankTransferPaymentCompleted,
            primaryText: t("myOrder.balancePaymentCompleted"),
            price:
              cashData.totalPrice -
              (isB2BSelected ? b2bDepositAmount : depositAmount),
            dataId: "cashfinancing-bankTransferPayment",
          },
        ],
      },
    ];
  });

  return <CashFinancingContent structure={structure} />;
};

CashFinancing.propTypes = {
  isB2BSelected: bool.isRequired,
  deal: dealType.isRequired,
  cashData: financingCashData,
  isModifiable: bool,
  onModifyButtonClick: func,
  withDeliveryInfo: bool,
  vehicleQuantity: number,
  isQuantityVisible: bool,
  bankTransferPaymentCompleted: bool,
  isReadOnly: bool,
};

export default CashFinancing;
