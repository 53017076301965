import equal from "fast-deep-equal";
import PropTypes, { arrayOf, object } from "prop-types";
import React, { Fragment, memo } from "react";
import styled, { css, useTheme } from "styled-components";

import Divider from "components/shared/Divider";
import CloseIcon from "components/shared/Icons/Close";
import PriceFormatter from "components/shared/PriceFormatter";
import Typography from "components/shared/Typography";
import { color, font, fontWeight } from "components/shared/utils";

const itemShape = PropTypes.shape({
  type: PropTypes.oneOf([
    "item",
    "items",
    "promocode",
    "divider",
    "formattedPrice",
    "group",
    "typography",
    "content",
    "customItem",
  ]).isRequired,
  isVisible: PropTypes.any,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  dataId: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  content: PropTypes.node,
  spacing: PropTypes.string,
  isRemovable: PropTypes.bool,
  onRemove: PropTypes.func,
  removeTitle: PropTypes.string,
  margin: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      primaryText: PropTypes.string,
      secondaryText: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      dataId: PropTypes.string,
      type: PropTypes.string,
      content: PropTypes.node,
    })
  ),
});

const SList = styled.ul`
  color: ${color("black")};
  list-style: none;
  margin: 0;
`;

const SListItem = styled.li`
  margin: 0.25rem 0;
`;

const SPriceContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}
`;

const SPrimaryText = styled(Typography)`
  font-family: ${font("citroen")};
  font-weight: ${({ regular }) =>
    regular ? fontWeight("regular") : fontWeight("bold")};
  text-transform: uppercase;
`;

const SSecondaryText = styled(Typography)`
  padding-left: 1rem;
`;

const STypography = styled(Typography)`
  font-family: ${font("citroen")};
`;

const SPromoCodeRemoveButton = styled.button`
  appearance: none;
  background: 0;
  border: 0;
  cursor: pointer;
  display: inline-grid;
  margin-left: 0.625rem;
  height: 1.1875rem;
  place-items: center;
  transition: all 0.25s ease-out;
  width: 1.1875rem;

  &:hover {
    color: ${color("errorRed")};
    transform: scale(1.2);
  }

  &:active {
    transform: scale(0.9);
  }
`;

const BasicItem = ({ item, theme }) => {
  switch (item.type) {
    case "content":
      return item.content;

    case "customItem":
      return (
        <SListItem>
          <SPriceContainer>
            <SPrimaryText>{item.primaryText}</SPrimaryText>
            {item.content}
          </SPriceContainer>
        </SListItem>
      );

    case "divider":
      return <Divider spacing={item.spacing} />;

    case "formattedPrice":
      return (
        <SListItem>
          <SPriceContainer italic>
            <SPrimaryText regular>{item.primaryText}</SPrimaryText>
            <STypography uppercase>{item.price}</STypography>
          </SPriceContainer>
        </SListItem>
      );

    case "item":
      return (
        <SListItem>
          <SPriceContainer>
            <SPrimaryText>{item.primaryText}</SPrimaryText>
            <PriceFormatter
              showOnlyCash
              amount={item.price}
              fontSizeInRem={{ default: 1 }}
              color={theme.colors.black}
              data-id={item.dataId}
            />
          </SPriceContainer>
        </SListItem>
      );

    case "items":
      return (
        <SListItem>
          {item.primaryText && (
            <SPriceContainer>
              <SPrimaryText>{item.primaryText}</SPrimaryText>
            </SPriceContainer>
          )}
          {item.items.map(subItem => (
            <SPriceContainer key={subItem.dataId}>
              {subItem.primaryText && (
                <SPrimaryText>{subItem.primaryText}</SPrimaryText>
              )}
              {subItem.secondaryText && (
                <SSecondaryText>{subItem.secondaryText}</SSecondaryText>
              )}
              <PriceFormatter
                showOnlyCash
                amount={Number(subItem.price)}
                fontSizeInRem={{ default: 1 }}
                color={theme.colors.black}
                data-id={subItem.dataId}
              />
            </SPriceContainer>
          ))}
        </SListItem>
      );

    case "promocode":
      return (
        <SListItem>
          <SPriceContainer>
            <SPrimaryText>
              {item.primaryText}
              {item.isRemovable && (
                <SPromoCodeRemoveButton
                  onClick={item.onRemove}
                  title={item.removeTitle}
                >
                  <CloseIcon size="sm" />
                </SPromoCodeRemoveButton>
              )}
            </SPrimaryText>
            <PriceFormatter
              showOnlyCash
              amount={item.price}
              fontSizeInRem={{ default: 1 }}
              color={theme.colors.black}
              data-id={item.dataId}
            />
          </SPriceContainer>
        </SListItem>
      );

    case "typography":
      return (
        <Typography size="sm" margin={item.margin ?? "0.5rem 0"}>
          {item.content}
        </Typography>
      );

    default:
      return null;
  }
};

BasicItem.propTypes = {
  item: itemShape,
  theme: object,
};

const _CashFinancingContent = ({ structure }) => {
  const theme = useTheme();

  return (
    <SList>
      {structure?.map?.((item, idx) => {
        if (!item.isVisible) {
          return null;
        }

        const key = `${idx}-${item.type}`;

        switch (item.type) {
          case "group":
            return (
              <Fragment key={key}>
                {item.items?.map?.((subItem, subIdx) => (
                  <BasicItem
                    key={`${key}-s-${subIdx}`}
                    {...{ item: subItem, theme }}
                  />
                ))}
              </Fragment>
            );

          default:
            return <BasicItem key={key} {...{ item, theme }} />;
        }
      })}
    </SList>
  );
};

_CashFinancingContent.propTypes = {
  structure: arrayOf(itemShape),
};

export default memo(_CashFinancingContent, equal);
